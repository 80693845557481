<template>
  <div class="about-us">
    <company-presentation></company-presentation>
    <Footer></Footer>
  </div>
</template>

<script>
  import CompanyPresentation from './childComps/CompanyPresentation.vue'
  import Footer from '@components/common/Footer.vue'

  export default {
    name: "Aboutus",
    components: {
      CompanyPresentation,
      Footer
    },
    data() {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .about-us {
    width: 100%;
    background-color: #1a2739;
  }
</style>