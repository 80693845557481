<template>
  <section class="company-presentation">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
      <el-tab-pane label="公司简介" name="first" class="tab-item">
        <div v-if="activeName==='first'">
          <h4>万云是一家什么样的公司？</h4>
          <div class="tab-content">
            <strong>北京万云科技开发有限公司</strong> 隶属于北京市气象局，由中国气象局北京城市技术研究中心、北京市气象台等八家股东共同出资组建。公司在北京市海淀区高新技术产业区注册成立，性质为有限责任公司，注册资金2000万元。公司经营管理部门有气象信息传媒经营部、气象信息网络开发部、数字气象部、综合开发部和计划财务部。全资子公司包括北京万云易博达软件有限公司、北京万云安德防雷工程有限公司和北京彩虹天通科技开发有限公司。
            <p>公司荟萃了北京地区气象部门杰出人才，依托气象部门独特、独有的资源优势，致力于首都气象科技服务的发展。公司紧跟气象科技服务迅猛发展的国际竞争态势，在我国率先开拓了气象科技服务领域。公司以其科学的管理模式，丰富的营销策略，先进多变的服务手段，致力于气象科技产品的开发，并吸引了大批高、精、尖人才，具有广阔的发展前景。</p>
            <p>公司主营业务包括：电视天气预报制作发布、广告宣传策划、专题片拍摄、专业气象服务的市场开发、手机短信、平面媒体广告、雷电防护装置产品检测、防雷工程设计安装、计算机网络辑软件设计、开发、维护等。</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="公司奖项" name="second" class="tab-item">
        <div v-if="activeName==='second'">
          <h4>荣誉奖项</h4>
          <div class="tab-content">
            <strong>北京万云科技开发有限公司</strong> 隶属于北京市气象局，由中国气象局北京城市技术研究中心、北京市气象台等八家股东共同出资组建。公司在北京市海淀区高新技术产业区注册成立，性质为有限责任公司，注册资金2000万元。公司经营管理部门有气象信息传媒经营部、气象信息网络开发部、数字气象部、综合开发部和计划财务部。全资子公司包括北京万云易博达软件有限公司、北京万云安德防雷工程有限公司和北京彩虹天通科技开发有限公司。
            <p>公司荟萃了北京地区气象部门杰出人才，依托气象部门独特、独有的资源优势，致力于首都气象科技服务的发展。公司紧跟气象科技服务迅猛发展的国际竞争态势，在我国率先开拓了气象科技服务领域。公司以其科学的管理模式，丰富的营销策略，先进多变的服务手段，致力于气象科技产品的开发，并吸引了大批高、精、尖人才，具有广阔的发展前景。</p>
            <p>公司主营业务包括：电视天气预报制作发布、广告宣传策划、专题片拍摄、专业气象服务的市场开发、手机短信、平面媒体广告、雷电防护装置产品检测、防雷工程设计安装、计算机网络辑软件设计、开发、维护等。</p>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
export default {
  name: "CompanyPresentation",
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
    }
  }
}
</script>

<style lang="scss" scoped>
  .company-presentation {
    margin-bottom: 168px;
    ::v-deep .tabs {
      width: 1206px;
      margin: 0 auto;
      .el-tabs__header {
        margin: 0 0 110px 0;
        padding: 40px 0;
        .el-tabs__nav-wrap {
          height: 55px;
          &::after {
            z-index: -99;
          }
          .el-tabs__nav-scroll {
            height: 55px;
            .el-tabs__nav {
              position: relative;
              &::after {
                content: '';
                position: absolute;
                top: 8px;
                left: 158px;
                width: 4px;
                height: 25px;
                background-color: #fff;
              }
              .el-tabs__item {
                padding: 0 25px!important;
                font-size: 24px;
                letter-spacing: 4px;
                font-weight: 700;
                color: #fff;
                &:hover {
                  color: #25ecff;
                }
              }
              .el-tabs__item.is-active {
                color: #25ecff;
              }
              .el-tabs__active-bar {
                width: 150px!important;
                height: 4px;
                left: -20px;
                bottom: -15px;
                background-color: #25ecff;
              }
            }
          }
        }
      }
      .el-tabs__content {
        .el-tab-pane {
          div {
            h4 {
              font-size: 36px;
              letter-spacing: 13px;
              font-weight: 700;
              text-align: center;
              margin-bottom: 90px;
            }
            .tab-content {
              width: 1206px;
              height: 340px;
              padding: 0 40px;
              font-size: 14px;
              column-count: 2;
              column-gap: 60px;
              -webkit-column-count: 2;
              -moz-column-count: 2;
              -webkit-column-gap: 60px;
              -moz-column-gap: 60px;
              text-indent: 40px;
              letter-spacing: 2px;
              line-height: 36px;
              strong {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
</style>